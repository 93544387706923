import * as React from "react"
import { Link } from "gatsby"

const NotFoundPage = () => {
  return (
   <code>404</code>
  )
}

export default NotFoundPage
